/* DIAMOND GRID LAYER - START */

@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);

@mixin cf {
	&:after {
		content: "";
		display: grid;
		clear: both;
	}
}
@mixin center {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

$negativeBackgrounds: #222, #333;
$repeat: 30;

@for $i from 1 through $repeat {
	.grid-negative:nth-child(#{length($negativeBackgrounds)}n + #{$i}) {
		background: lighten(
			nth($negativeBackgrounds, random(length($negativeBackgrounds))),
			5%
		);
	}
}

@function sqrt($r) {
	$x0: 1;
	$x1: $x0;
	@for $i from 1 through 10 {
		$x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
		$x0: $x1;
	}
	@return $x1;
}

$gridUnit: (100vh / 10);
$transformUnit: $gridUnit / sqrt(2);
$small: $transformUnit * 2;
$large: $transformUnit * 4;
.grid-wrapper {
	@include cf;
	@include center;
	position: relative;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	background: #222;
	background-image: url("../../assets/stars.svg");
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: bottom right;
	visibility: hidden;
}

.grid-negative {
	opacity: 0.2 !important;
}

.notSafariHover {
	box-shadow: inset 3px 3px 3px 3px #333;
	-webkit-transition: box-shadow 0.3s ease-in-out;
	-moz-transition: box-shadow 0.3s ease-in-out;
	transition: box-shadow 0.3s ease-in-out;

	&:hover {
		box-shadow: inset 0 0 0px #333, 10px 10px 10px 0px #000;
		cursor: pointer;
		z-index: 9999 !important;
	}
}

.grid-item {
	position: absolute;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	transform-origin: 0% 0%;
}

.diamondPieceArticle {
	opacity: 0;
	z-index: -1;
	display: none;
}
.diamond-large {
	height: $large;
	width: $large;
	border: 1px solid #888;
	// visibility: hidden;
	overflow: hidden;
	.diamond__content {
		height: $large;
		width: $large;
		display: table-cell;
		text-align: center;
		vertical-align: middle;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	.diamond__img {
		width: $gridUnit * 4;
		-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
	}
}

.brand-logo {
	position: absolute;
	top: 5%;
	left: 1.5%;
	width: 10%;
	height: 10%;
	background: url("../../assets/SJ_ChildGraphic_Red_RGB.svg");
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 9999;
	opacity: 0;
	border-color: transparent;

	&:hover {
		cursor: pointer;
	}
	&:focus {
		//outline: 0.3rem solid #0000ff;
	}
}

#purposeHeading {
	color: #999;
	width: 25vw;
	height: 15vh;
	z-index: 9999;
	position: absolute;
	left: 2vw;
	top: 35vh;
	opacity: 0;
	background: url("../../assets/the-purpose-that-unites-us-tagline.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

#wordmarkText {
	position: absolute;
	top: 50vh;
	left: 2vw;
	width: 25vw;
	height: 15vh;
	z-index: 9998;
	color: #222;
	opacity: 0;
}

.diamond-sp {
	background: #fff;
	height: $transformUnit * 14;
	width: $transformUnit * 14;
	border: 1px solid white;
	top: $gridUnit * -2;
	left: $gridUnit * 1;
	z-index: 9997;
}

.temp_diamond_content {
	height: 150%;
	width: 150%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	-webkit-transform: rotate(-45deg) translateY(-$gridUnit);
	-ms-transform: rotate(-45deg) translateY(-$gridUnit);
	transform: rotate(-45deg) translateY(-$gridUnit);
	z-index: 9998 !important;
}

// LAYOUT 1 START ------------------------------------------------------------------------------
.layout-1 {
	& > .diamond-a1 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a2 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a3 {
		top: $gridUnit * 1;
		left: $gridUnit * 16;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a4 {
		top: $gridUnit * 3;
		left: $gridUnit * 10;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a5 {
		top: $gridUnit * 7;
		left: $gridUnit * 10;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a6 {
		top: $gridUnit * 5;
		left: $gridUnit * 16;
		background: white;
		z-index: 1000;
	}

	.diamond-n1 {
		top: $gridUnit * -3;
		left: $gridUnit * 4;
	}
	.diamond-n2 {
		top: $gridUnit * -3;
		left: $gridUnit * 8;
	}
	.diamond-n3 {
		top: $gridUnit * -3;
		left: $gridUnit * 12;
	}
	.diamond-n4 {
		top: $gridUnit * -3;
		left: $gridUnit * 16;
	}
	.diamond-n5 {
		top: $gridUnit * -3;
		left: $gridUnit * 20;
	}
	.diamond-n6 {
		top: $gridUnit * -3;
		left: $gridUnit * 24;
	}
	.diamond-n7 {
		top: $gridUnit * -1;
		left: $gridUnit * 6;
	}
	.diamond-n8 {
		top: $gridUnit * -1;
		left: $gridUnit * 10;
	}
	.diamond-n9 {
		top: $gridUnit * -1;
		left: $gridUnit * 18;
	}
	.diamond-n10 {
		top: $gridUnit * -1;
		left: $gridUnit * 22;
	}
	.diamond-n11 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
	}
	.diamond-n12 {
		top: $gridUnit * 1;
		left: $gridUnit * 16;
	}
	.diamond-n13 {
		top: $gridUnit * 1;
		left: $gridUnit * 24;
	}
	.diamond-n14 {
		top: $gridUnit * 3;
		left: $gridUnit * 10;
	}
	.diamond-n15 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
	}
	.diamond-n16 {
		top: $gridUnit * 3;
		left: $gridUnit * 18;
	}
	.diamond-n17 {
		top: $gridUnit * 3;
		left: $gridUnit * 22;
	}
	.diamond-n18 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
	}
	.diamond-n19 {
		top: $gridUnit * 5;
		left: $gridUnit * 16;
	}
	.diamond-n20 {
		top: $gridUnit * 7;
		left: $gridUnit * 14;
	}
	.diamond-n21 {
		top: $gridUnit * 5;
		left: $gridUnit * 24;
	}
	.diamond-n22 {
		top: $gridUnit * 7;
		left: $gridUnit * 10;
	}
	.diamond-n23 {
		top: $gridUnit * 7;
		left: $gridUnit * 18;
	}
	.diamond-n24 {
		top: $gridUnit * 7;
		left: $gridUnit * 22;
	}
	.diamond-n25 {
		top: $gridUnit * 9;
		left: $gridUnit * 4;
	}
	.diamond-n26 {
		top: $gridUnit * 9;
		left: $gridUnit * 8;
	}
	.diamond-n27 {
		top: $gridUnit * 9;
		left: $gridUnit * 12;
	}
	.diamond-n28 {
		top: $gridUnit * 9;
		left: $gridUnit * 16;
	}
	.diamond-n29 {
		top: $gridUnit * 9;
		left: $gridUnit * 20;
	}
	.diamond-n30 {
		top: $gridUnit * 9;
		left: $gridUnit * 24;
	}
	.diamond-n31 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
	}
	.diamond-n32 {
		top: $gridUnit * -1;
		left: $gridUnit * 14;
	}
	.diamond-n33 {
		top: $gridUnit * 1;
		left: $gridUnit * 20;
	}
	.diamond-n34 {
		top: $gridUnit * 7;
		left: $gridUnit * 6;
	}
	.diamond-n35 {
		top: $gridUnit * 5;
		left: $gridUnit * 12;
	}
	.diamond-n36 {
		top: $gridUnit * 5;
		left: $gridUnit * 20;
	}

	@media only screen and (min-width: 2501px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 15vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 900px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1900px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 20vw;
		}
	}

	@media only screen and (max-width: 1800px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1700px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1620px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 11.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1475px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 10vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 1000px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 7.5vh;
			left: 1vw;
			width: 12.5vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 15vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 15vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		// .diamond-n26,
		// .diamond-n27,
		// .diamond-n28,
		// .diamond-n29,
		// .diamond-n30,
		// .diamond-n31,
		// .diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -2.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 20vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 20vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1200px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			-ms-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			transform: rotate(-45deg) translateY(-$responsiveGridUnit);
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -3;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 11;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 15;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 9;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 9;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 15;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 3;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 7;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 11;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 15;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 5;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 5;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 3;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 21;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 20vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 20vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 13.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -0.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 18;
			width: $responsiveTransformUnit * 18;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 1%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
			width: 25vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 25vw;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 17.5vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 17.5vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 600px) {
		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 52.5vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 17.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 15vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 896px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1.5%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 834px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 812px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1%;
			width: 20vw;
			height: 15vh;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 800px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 37.5%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 736px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 11);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			height: 15vh;
			left: 1vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 640px) and (orientation: portrait) {
	}

	@media only screen and (max-width: 640px) and (orientation: landscape) {
	}

	@media only screen and (max-width: 480px) and (orientation: portrait) {
	}

	@media only screen and (max-width: 480px) and (orientation: landscape) {
	}

	@media only screen and (max-width: 414px) and (orientation: portrait) {
		$responsiveGridUnit: 8.3325vw;
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			width: $responsiveGridUnit * 5;
			height: $responsiveGridUnit * 5;
			position: absolute;
			top: 20%;
			left: 20%;
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
		}

		.grid-negative {
			opacity: 1 !important;
		}

		.brand-logo {
			top: 3vh;
			left: 37.5vw;
			width: 25vw;
			height: 25vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 50;
			width: $responsiveTransformUnit * 50;
			top: $responsiveGridUnit * -10;
			left: $responsiveGridUnit * 6;
			z-index: 0;
		}

		#purposeHeading {
			left: 33vw;
			top: 75vh;
			width: 35vw;
		}

		#wordmarkText {
			display: none;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 10;
			display: block;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 0;
			display: block;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 12;
			display: block;
		}
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 620px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
		}

		#purposeHeading {
			left: 30vw;
			top: 80vh;
			width: 40vw;
		}
	}

	// 8+
	@media only screen and (max-width: 414px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 715px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}
	}

	// 12 pro, 13 pro (too high), 12 proMax, Xs Max
	@media only screen and (max-width: 414px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
		}
	}

	//
	@media only screen and (max-width: 414px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	// 12
	@media only screen and (max-width: 390px) and (min-height: 664px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
		}
	}

	@media only screen and (max-width: 375px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// SE
	@media only screen and (max-width: 375px) and (min-height: 552px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
		}

		#purposeHeading {
			left: 30vw;
			top: 79.5vh;
			width: 40vw;
		}
	}

	// 8
	@media only screen and (max-width: 375px) and (min-height: 629px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
		}

		#purposeHeading {
			left: 22.5vw;
			top: 70vh;
			width: 55vw;
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 635px) and (orientation: portrait) {
		#purposeHeading {
			left: 22.5vw;
			top: 75vh;
			width: 55vw;
		}
	}

	// 12 mini
	@media only screen and (max-width: 375px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// 11 pro, X
	@media only screen and (max-width: 375px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}
}
// LAYOUT 1 END ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// LAYOUT 2 START ------------------------------------------------------------------------------
.layout-2 {
	& > .diamond-a1 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
	}

	& > .diamond-a2 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
	}

	& > .diamond-a3 {
		top: $gridUnit * 1;
		left: $gridUnit * 16;
	}

	& > .diamond-a4 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
	}

	& > .diamond-a5 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
	}

	& > .diamond-a6 {
		top: $gridUnit * 5;
		left: $gridUnit * 12;
	}

	.diamond-n1 {
		top: $gridUnit * -3;
		left: $gridUnit * 4;
	}
	.diamond-n2 {
		top: $gridUnit * -3;
		left: $gridUnit * 8;
	}
	.diamond-n3 {
		top: $gridUnit * -3;
		left: $gridUnit * 12;
	}
	.diamond-n4 {
		top: $gridUnit * -3;
		left: $gridUnit * 16;
	}
	.diamond-n5 {
		top: $gridUnit * -3;
		left: $gridUnit * 20;
	}
	.diamond-n6 {
		top: $gridUnit * -3;
		left: $gridUnit * 24;
	}
	.diamond-n7 {
		top: $gridUnit * -1;
		left: $gridUnit * 6;
	}
	.diamond-n8 {
		top: $gridUnit * -1;
		left: $gridUnit * 10;
	}
	.diamond-n9 {
		top: $gridUnit * -1;
		left: $gridUnit * 18;
	}
	.diamond-n10 {
		top: $gridUnit * -1;
		left: $gridUnit * 22;
	}
	.diamond-n11 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
	}
	.diamond-n12 {
		top: $gridUnit * 1;
		left: $gridUnit * 16;
	}
	.diamond-n13 {
		top: $gridUnit * 1;
		left: $gridUnit * 24;
	}
	.diamond-n14 {
		top: $gridUnit * 3;
		left: $gridUnit * 10;
	}
	.diamond-n15 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
	}
	.diamond-n16 {
		top: $gridUnit * 3;
		left: $gridUnit * 18;
	}
	.diamond-n17 {
		top: $gridUnit * 3;
		left: $gridUnit * 22;
	}
	.diamond-n18 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
	}
	.diamond-n19 {
		top: $gridUnit * 5;
		left: $gridUnit * 16;
	}
	.diamond-n20 {
		top: $gridUnit * 7;
		left: $gridUnit * 14;
	}
	.diamond-n21 {
		top: $gridUnit * 5;
		left: $gridUnit * 24;
	}
	.diamond-n22 {
		top: $gridUnit * 7;
		left: $gridUnit * 10;
	}
	.diamond-n23 {
		top: $gridUnit * 7;
		left: $gridUnit * 18;
	}
	.diamond-n24 {
		top: $gridUnit * 7;
		left: $gridUnit * 22;
	}
	.diamond-n25 {
		top: $gridUnit * 9;
		left: $gridUnit * 4;
	}
	.diamond-n26 {
		top: $gridUnit * 9;
		left: $gridUnit * 8;
	}
	.diamond-n27 {
		top: $gridUnit * 9;
		left: $gridUnit * 12;
	}
	.diamond-n28 {
		top: $gridUnit * 9;
		left: $gridUnit * 16;
	}
	.diamond-n29 {
		top: $gridUnit * 9;
		left: $gridUnit * 20;
	}
	.diamond-n30 {
		top: $gridUnit * 9;
		left: $gridUnit * 24;
	}
	.diamond-n31 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
	}
	.diamond-n32 {
		top: $gridUnit * -1;
		left: $gridUnit * 14;
	}
	.diamond-n33 {
		top: $gridUnit * 1;
		left: $gridUnit * 20;
	}
	.diamond-n34 {
		top: $gridUnit * 7;
		left: $gridUnit * 6;
	}
	.diamond-n35 {
		top: $gridUnit * 5;
		left: $gridUnit * 12;
	}
	.diamond-n36 {
		top: $gridUnit * 5;
		left: $gridUnit * 20;
	}

	@media only screen and (min-width: 2501px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 15vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 900px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1900px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 20vw;
		}
	}

	@media only screen and (max-width: 1800px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1700px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1620px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 11.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1475px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 10vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 1000px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 7.5vh;
			left: 1vw;
			width: 12.5vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 15vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 15vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		// .diamond-n26,
		// .diamond-n27,
		// .diamond-n28,
		// .diamond-n29,
		// .diamond-n30,
		// .diamond-n31,
		// .diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -2.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 20vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 20vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1200px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			-ms-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			transform: rotate(-45deg) translateY(-$responsiveGridUnit);
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -3;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 11;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 15;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 13;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 7;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 11;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 3;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 7;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 11;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 15;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 5;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 5;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 3;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 21;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 20vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 20vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 13.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -0.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 18;
			width: $responsiveTransformUnit * 18;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 1%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
			width: 25vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 25vw;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 17.5vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 17.5vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 600px) {
		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 52.5vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 17.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 15vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 896px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1.5%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 834px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 812px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1%;
			width: 20vw;
			height: 15vh;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 800px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 37.5%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 736px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 11);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			height: 15vh;
			left: 1vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 414px) and (orientation: portrait) {
		$responsiveGridUnit: 8.3325vw;
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			width: $responsiveGridUnit * 5;
			height: $responsiveGridUnit * 5;
			position: absolute;
			top: 20%;
			left: 20%;
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
		}

		.grid-negative {
			opacity: 1 !important;
		}

		.brand-logo {
			top: 3vh;
			left: 37.5vw;
			width: 25vw;
			height: 25vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 50;
			width: $responsiveTransformUnit * 50;
			top: $responsiveGridUnit * -10;
			left: $responsiveGridUnit * 6;
			z-index: 0;
		}

		#purposeHeading {
			left: 33vw;
			top: 75vh;
			width: 35vw;
		}

		#wordmarkText {
			display: none;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 10;
			display: block;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 0;
			display: block;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 12;
			display: block;
		}
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 620px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
		}

		#purposeHeading {
			left: 30vw;
			top: 80vh;
			width: 40vw;
		}
	}

	// 8+
	@media only screen and (max-width: 414px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 715px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}
	}

	// 12 pro, 13 pro (too high), 12 proMax, Xs Max
	@media only screen and (max-width: 414px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
		}
	}

	//
	@media only screen and (max-width: 414px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	// 12
	@media only screen and (max-width: 390px) and (min-height: 664px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
		}
	}

	@media only screen and (max-width: 375px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// SE
	@media only screen and (max-width: 375px) and (min-height: 552px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
		}

		#purposeHeading {
			left: 30vw;
			top: 79.5vh;
			width: 40vw;
		}
	}

	// 8
	@media only screen and (max-width: 375px) and (min-height: 629px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
		}

		#purposeHeading {
			left: 22.5vw;
			top: 70vh;
			width: 55vw;
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 635px) and (orientation: portrait) {
		#purposeHeading {
			left: 22.5vw;
			top: 75vh;
			width: 55vw;
		}
	}

	// 12 mini
	@media only screen and (max-width: 375px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// 11 pro, X
	@media only screen and (max-width: 375px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}
}
// LAYOUT 2 END ------------------------------------------------------------------------------

// LAYOUT 3 START ------------------------------------------------------------------------------
.layout-3 {
	& > .diamond-a1 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a2 {
		top: $gridUnit * 3;
		left: $gridUnit * 10;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a3 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a4 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a5 {
		top: $gridUnit * 5;
		left: $gridUnit * 12;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a6 {
		top: $gridUnit * 5;
		left: $gridUnit * 16;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a7 {
		top: $gridUnit * -1;
		left: $gridUnit * 6;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a8 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
		background: white;
		z-index: 1000;
	}

	& > .diamond-a9 {
		top: $gridUnit * -1;
		left: $gridUnit * 18;
		background: white;
		z-index: 1000;
	}

	& > & > .diamond-a10 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
		background: white;
		z-index: 1000;
	}

	& > & > .diamond-a11 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
		background: white;
		z-index: 1000;
	}

	& > & > .diamond-a12 {
		top: $gridUnit * 7;
		left: $gridUnit * 18;
		background: white;
		z-index: 1000;
	}

	.diamond-n1 {
		top: $gridUnit * -3;
		left: $gridUnit * 4;
	}
	.diamond-n2 {
		top: $gridUnit * -3;
		left: $gridUnit * 8;
	}
	.diamond-n3 {
		top: $gridUnit * -3;
		left: $gridUnit * 12;
	}
	.diamond-n4 {
		top: $gridUnit * -3;
		left: $gridUnit * 16;
	}
	.diamond-n5 {
		top: $gridUnit * -3;
		left: $gridUnit * 20;
	}
	.diamond-n6 {
		top: $gridUnit * -3;
		left: $gridUnit * 24;
	}
	.diamond-n7 {
		top: $gridUnit * -1;
		left: $gridUnit * 6;
	}
	.diamond-n8 {
		top: $gridUnit * -1;
		left: $gridUnit * 10;
	}
	.diamond-n9 {
		top: $gridUnit * -1;
		left: $gridUnit * 18;
	}
	.diamond-n10 {
		top: $gridUnit * -1;
		left: $gridUnit * 22;
	}
	.diamond-n11 {
		top: $gridUnit * 1;
		left: $gridUnit * 12;
	}
	.diamond-n12 {
		top: $gridUnit * 1;
		left: $gridUnit * 16;
	}
	.diamond-n13 {
		top: $gridUnit * 1;
		left: $gridUnit * 24;
	}
	.diamond-n14 {
		top: $gridUnit * 3;
		left: $gridUnit * 10;
	}
	.diamond-n15 {
		top: $gridUnit * 3;
		left: $gridUnit * 14;
	}
	.diamond-n16 {
		top: $gridUnit * 3;
		left: $gridUnit * 18;
	}
	.diamond-n17 {
		top: $gridUnit * 3;
		left: $gridUnit * 22;
	}
	.diamond-n18 {
		top: $gridUnit * 5;
		left: $gridUnit * 8;
	}
	.diamond-n19 {
		top: $gridUnit * 5;
		left: $gridUnit * 16;
	}
	.diamond-n20 {
		top: $gridUnit * 7;
		left: $gridUnit * 14;
	}
	.diamond-n21 {
		top: $gridUnit * 5;
		left: $gridUnit * 24;
	}
	.diamond-n22 {
		top: $gridUnit * 7;
		left: $gridUnit * 10;
	}
	.diamond-n23 {
		top: $gridUnit * 7;
		left: $gridUnit * 18;
	}
	.diamond-n24 {
		top: $gridUnit * 7;
		left: $gridUnit * 22;
	}
	.diamond-n25 {
		top: $gridUnit * 9;
		left: $gridUnit * 4;
	}
	.diamond-n26 {
		top: $gridUnit * 9;
		left: $gridUnit * 8;
	}
	.diamond-n27 {
		top: $gridUnit * 9;
		left: $gridUnit * 12;
	}
	.diamond-n28 {
		top: $gridUnit * 9;
		left: $gridUnit * 16;
	}
	.diamond-n29 {
		top: $gridUnit * 9;
		left: $gridUnit * 20;
	}
	.diamond-n30 {
		top: $gridUnit * 9;
		left: $gridUnit * 24;
	}
	.diamond-n31 {
		top: $gridUnit * 1;
		left: $gridUnit * 8;
	}
	.diamond-n32 {
		top: $gridUnit * -1;
		left: $gridUnit * 14;
	}
	.diamond-n33 {
		top: $gridUnit * 1;
		left: $gridUnit * 20;
	}
	.diamond-n34 {
		top: $gridUnit * 7;
		left: $gridUnit * 6;
	}
	.diamond-n35 {
		top: $gridUnit * 5;
		left: $gridUnit * 12;
	}
	.diamond-n36 {
		top: $gridUnit * 5;
		left: $gridUnit * 20;
	}

	@media only screen and (min-width: 2501px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 15vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 30;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 30;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 9);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -6;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 17.5vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 24;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 26;
		}
		.diamond-n33 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n34 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n35 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 28;
		}
		.diamond-n36 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
	}

	@media only screen and (max-width: 2500px) and (min-height: 900px) {
		#purposeHeading {
			//NEW
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			// NEW
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1900px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 20vw;
		}
	}

	@media only screen and (max-width: 1800px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1700px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1620px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 11.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1475px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 600px) {
		#purposeHeading {
			width: 25vw;
			left: 1vw;
			top: 30vh;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
		}
	}

	@media only screen and (max-width: 1400px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 10vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1366px) and (min-height: 1000px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 7.5vh;
			left: 1vw;
			width: 12.5vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 15vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 15vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		// .diamond-n26,
		// .diamond-n27,
		// .diamond-n28,
		// .diamond-n29,
		// .diamond-n30,
		// .diamond-n31,
		// .diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 10);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -2.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 30vh;
			width: 20vw;
		}

		#wordmarkText {
			top: 48vh;
			left: 1vw;
			width: 20vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 12.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 20vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 20vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1280px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 16;
			width: $responsiveTransformUnit * 16;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1200px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			-ms-transform: rotate(-45deg) translateY(-$responsiveGridUnit);
			transform: rotate(-45deg) translateY(-$responsiveGridUnit);
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 22;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1150px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -3;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 9;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 13;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 7;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 11;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 15;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 3;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 7;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 11;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 15;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 5;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 5;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 9;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 13;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 17;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 3;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 7;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 11;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 15;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 21;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 21;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 19;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 20vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 20vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -4;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 14);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1112px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 600px) {
		$responsiveGridUnit: (100vh / 12);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 700px) {
		$responsiveGridUnit: (100vh / 13.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1.5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 4.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 8.5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 0.5;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 2.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 6.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 10.5;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 14.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 15;
			width: $responsiveTransformUnit * 15;
			top: $responsiveGridUnit * -0.5;
			left: $responsiveGridUnit * 0.5;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 22.5vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 22.5vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 16.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 18;
			width: $responsiveTransformUnit * 18;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 1%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
			width: 25vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 25vw;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (orientation: landscape) {
		#purposeHeading {
			left: 1%;
			top: 37.5%;
			width: 17.5vw;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			width: 17.5vw;
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 600px) {
		#purposeHeading {
			left: 1vw;
			top: 35vh;
			width: 25vw;
		}

		#wordmarkText {
			top: 50vh;
			left: 1vw;
			width: 25vw;
			font-size: 0.8rem;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 800px) {
		$responsiveGridUnit: (100vh / 15.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 37.5vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 52.5vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 22;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 1024px) and (min-height: 900px) {
		$responsiveGridUnit: (100vh / 17.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 15vh;
			left: 1vw;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			left: 1vw;
			top: 40vh;
			width: 25vw;
		}

		#wordmarkText {
			left: 1vw;
			top: 55vh;
			width: 25vw;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n27 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 24;
		}
		.diamond-n28 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n29 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n30 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n31 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}
		.diamond-n32 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 20;
		}

		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 896px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1.5%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 834px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 40%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 812px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 10.5);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 10%;
			left: 1%;
			width: 20vw;
			height: 15vh;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 20vw;
			height: 15vh;
			left: 2vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 2vw;
			top: 50vh;
			width: 20vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 800px) and (orientation: portrait) {
		$responsiveGridUnit: (100vh / 13.25);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 25%;
			left: 0.5%;
			width: 15vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 10;
			width: $responsiveTransformUnit * 10;
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * -1;
		}

		#purposeHeading {
			left: 1%;
			top: 37.5%;
		}

		#wordmarkText {
			top: 45%;
			left: 1%;
			font-size: 0.75rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -2;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 0;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 2;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 4;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 6;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 8;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 10;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 12;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n22,
		.diamond-n23,
		.diamond-n24,
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 736px) and (orientation: landscape) {
		$responsiveGridUnit: (100vh / 11);
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.brand-logo {
			top: 5%;
			left: 1%;
			width: 20vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 20;
			width: $responsiveTransformUnit * 20;
			top: $responsiveGridUnit * -5;
			left: $responsiveGridUnit * -2;
		}

		#purposeHeading {
			width: 25vw;
			height: 15vh;
			left: 1vw;
			top: 32.5vh;
		}

		#wordmarkText {
			left: 1vw;
			top: 50vh;
			width: 25vw;
			font-size: 0.55rem;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 12;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * -3;
			left: $responsiveGridUnit * 16;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * -1;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 14;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 18;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n25 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 16;
		}
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 414px) and (orientation: portrait) {
		$responsiveGridUnit: 8.3325vw;
		$responsiveTransformUnit: $responsiveGridUnit / sqrt(2);
		$responsiveLarge: $responsiveTransformUnit * 4;

		.diamond-large {
			height: $responsiveLarge;
			width: $responsiveLarge;
			.diamond__content {
				height: $responsiveLarge;
				width: $responsiveLarge;
			}
			.diamond__img {
				width: $responsiveGridUnit * 4;
			}
		}

		.temp_diamond_content {
			width: $responsiveGridUnit * 5;
			height: $responsiveGridUnit * 5;
			position: absolute;
			top: 20%;
			left: 20%;
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.86);
		}

		.grid-negative {
			opacity: 1 !important;
		}

		.brand-logo {
			top: 3vh;
			left: 37.5vw;
			width: 25vw;
			height: 25vw;
		}

		.diamond-sp {
			height: $responsiveTransformUnit * 50;
			width: $responsiveTransformUnit * 50;
			top: $responsiveGridUnit * -10;
			left: $responsiveGridUnit * 6;
			z-index: 0;
		}

		#purposeHeading {
			left: 33vw;
			top: 75vh;
			width: 35vw;
		}

		#wordmarkText {
			display: none;
		}

		& > .diamond-a1 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 2;
		}

		& > .diamond-a2 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 6;
		}

		& > .diamond-a3 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}

		& > .diamond-a4 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}

		& > .diamond-a5 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}

		& > .diamond-a6 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}

		.diamond-n1 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 2;
		}

		.diamond-n2 {
			top: $responsiveGridUnit * 1;
			left: $responsiveGridUnit * 10;
		}

		.diamond-n3 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 0;
		}

		.diamond-n4 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 4;
		}

		.diamond-n5 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 8;
		}

		.diamond-n6 {
			top: $responsiveGridUnit * 3;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n7 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n8 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n9 {
			top: $responsiveGridUnit * 5;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n10 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n11 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n12 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n13 {
			top: $responsiveGridUnit * 7;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n14 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n15 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 6;
		}
		.diamond-n16 {
			top: $responsiveGridUnit * 9;
			left: $responsiveGridUnit * 10;
		}
		.diamond-n17 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 0;
		}
		.diamond-n18 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 4;
		}
		.diamond-n19 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 8;
		}
		.diamond-n20 {
			top: $responsiveGridUnit * 11;
			left: $responsiveGridUnit * 12;
		}
		.diamond-n21 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 2;
		}
		.diamond-n22 {
			top: $responsiveGridUnit * 13;
			left: $responsiveGridUnit * 10;
			display: block;
		}
		.diamond-n23 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 0;
			display: block;
		}
		.diamond-n24 {
			top: $responsiveGridUnit * 15;
			left: $responsiveGridUnit * 12;
			display: block;
		}
		.diamond-n25,
		.diamond-n26,
		.diamond-n27,
		.diamond-n28,
		.diamond-n29,
		.diamond-n30,
		.diamond-n31,
		.diamond-n32,
		.diamond-n33,
		.diamond-n34,
		.diamond-n35,
		.diamond-n36 {
			display: none;
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 620px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.2);
		}

		#purposeHeading {
			left: 30vw;
			top: 80vh;
			width: 40vw;
		}
	}

	// 8+
	@media only screen and (max-width: 414px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.9);
		}
	}

	@media only screen and (max-width: 414px) and (min-height: 715px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}
	}

	// 12 pro, 13 pro (too high), 12 proMax, Xs Max
	@media only screen and (max-width: 414px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.78);
		}
	}

	//
	@media only screen and (max-width: 414px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	// 12
	@media only screen and (max-width: 390px) and (min-height: 664px) and (orientation: portrait) {
		#purposeHeading {
			left: 20vw;
			top: 75vh;
			width: 60vw;
		}

		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.785);
		}
	}

	@media only screen and (max-width: 375px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// SE
	@media only screen and (max-width: 375px) and (min-height: 552px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
			transform: rotate(-45deg) translateY(-$gridUnit * 1.1);
		}

		#purposeHeading {
			left: 30vw;
			top: 79.5vh;
			width: 40vw;
		}
	}

	// 8
	@media only screen and (max-width: 375px) and (min-height: 629px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.95);
		}

		#purposeHeading {
			left: 22.5vw;
			top: 70vh;
			width: 55vw;
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 635px) and (orientation: portrait) {
		#purposeHeading {
			left: 22.5vw;
			top: 75vh;
			width: 55vw;
		}
	}

	// 12 mini
	@media only screen and (max-width: 375px) and (min-height: 700px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}

	// 11 pro, X
	@media only screen and (max-width: 375px) and (min-height: 800px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.75);
		}
	}

	@media only screen and (max-width: 375px) and (min-height: 900px) and (orientation: portrait) {
		.temp_diamond_content {
			-webkit-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			-ms-transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
			transform: rotate(-45deg) translateY(-$gridUnit * 0.85);
		}
	}
}
// LAYOUT 3 END ------------------------------------------------------------------------------

/* DIAMOND GRID LAYER - END */
